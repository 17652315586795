import * as React from "react";
import { Navigation } from "./navigation";
import { EditOutlined, DeleteOutlined, PauseOutlined } from "@ant-design/icons";
import {
  Layout,
  theme,
  Table,
  Pagination,
  Select,
  Button,
  Popconfirm,
} from "antd";
import { Option } from "antd/es/mentions";
const { Content } = Layout;

export default function ArticlesList() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const handleEdit = (record) => {
    // Xử lý logic cho việc sửa bản ghi
  };

  const handleDelete = (key) => {
    // Xử lý logic cho việc xóa bản ghi
  };

  const handleHide = (key) => {
    // Xử lý logic cho việc tạm ẩn bản ghi
  };

  const columns = [
    {
      title: "Tên chủ bài viết",
      dataIndex: "Name",
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "NameLogin",
    },
    {
      title: "SĐT",
      dataIndex: "SDT",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <span>
          <Popconfirm
            title={`Bạn có chắc muốn xóa bài viết của ${record.Name} ra khỏi hệ thống?`}
            onConfirm={() => handleDelete(record.key)}
            okText="OK" // Văn bản của nút OK
            cancelText="Cancel" // Văn bản của nút Cancel
            okButtonProps={{
              style: { backgroundColor: "#ED5A2A", color: "white" },
            }} // Tùy chỉnh màu sắc nút OK
            cancelButtonProps={{
              style: { background: "#275DAB", color: "white" },
            }} // Tùy chỉnh màu sắc nút Cancel
          >
            <DeleteOutlined style={{ color: "red", marginRight: 8 }} />
          </Popconfirm>
          <Popconfirm
            title={`Bạn có chắc muốn tạm ẩn bài viết của ${record.Name}?`}
            onConfirm={() => handleHide(record.key)}
            okText="OK"
            cancelText="Cancel"
            okButtonProps={{ style: { background: "green", color: "white" } }}
            cancelButtonProps={{ style: { background: "red", color: "white" } }}
          >
            <PauseOutlined style={{ color: "orange", marginRight: 8 }} />{" "}
          </Popconfirm>
        </span>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      Name: "Lê Bá Tòng",
      NameLogin: "tonglb",
      SDT: "0366027514",
    },
    {
      key: "2",
      Name: "Người 2",
      NameLogin: "nguoi2",
      SDT: "0987654322",
    },
  ];

  // Phân trang
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10, // Số mục trên mỗi trang
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const handlePageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value });
  };
  return (
    <div className="flex flex-col flex-grow">
      <Navigation title={"Danh sách bài viết"} />
      <Content className="mx-6 my-4">
        <div style={{ height: "calc(100vh - 240px)" }}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onChange={handleTableChange}
            scroll={{ y: "calc(100vh - 270px)" }}
          />
        </div>
      </Content>
      <div className="flex justify-end mt-4">
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={data.length}
          onChange={(page, pageSize) => {
            setPagination({ ...pagination, current: page });
          }}
          showSizeChanger={false}
          showQuickJumper={true}
        />
        <Select
          defaultValue={pagination.pageSize}
          onChange={handlePageSizeChange}
          style={{ width: 120 }}
        >
          <Option value={5}>5 / page</Option>
          <Option value={10}>10 / page</Option>
          <Option value={50}>50 / page</Option>
        </Select>
      </div>
      <div className="text-center mt-2">
        Showing {pagination.pageSize} items on page {pagination.current}
      </div>
    </div>
  );
}
