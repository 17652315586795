import * as React from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { HorizontalLayout } from "./layout/HorizontalLayout";
import "./custom.css";

export default class App extends React.PureComponent {
    static displayName = App.name;

    render() {
        return (
            <HorizontalLayout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                </Routes>
            </HorizontalLayout>
        );
    }
}
