import * as React from "react";
import {
  DesktopOutlined,
  PieChartOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, ConfigProvider, theme, Image } from "antd";
import { useNavigate } from "react-router-dom";
//import {pepoTheme} from "./../framework/pepoTheme"
import * as Fi from "react-icons/fi";
import { pepoTheme } from "../framework/pepoTheme";

const AuthContext = React.createContext();
export { AuthContext };

const { Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Người dùng", "users", <PieChartOutlined />),
  getItem("Bài viết", "articles", <DesktopOutlined />),
  getItem("Thông báo", "notifications", <Fi.FiAirplay />),
  {
    type: "divider",
  },
  // getItem("User", "sub1", <UserOutlined />, [
  //   getItem("Tom", "3"),
  //   getItem("Bill", "4"),
  //   getItem("Alex", "5"),
  // ]),
  // getItem("Team", "sub2", <TeamOutlined />, [
  //   getItem("Team 1", "6"),
  //   getItem("Team 2", "8"),
  // ]),
  // getItem("Files", "9", <FileOutlined />),
];

export function HorizontalLayout(props) {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false);
  const [auth, setAuth] = React.useState("undefined");
  const [currentTheme, setCurrentTheme] = React.useState("light");

  const value = React.useMemo(
    () => ({ auth, setAuth, currentTheme, setCurrentTheme }),
    [auth, currentTheme]
  );
  React.useEffect(() => {
    console.log("Auth: ", auth);
    if (!auth) {
      navigate("/login");
    }
  }, [auth]);
  return (
    <ConfigProvider
      theme={{
        // 1. Use dark algorithm
        algorithm:
          currentTheme === "dark"
            ? theme.darkAlgorithm
            : theme.defaultAlgorithm,
        // 2. Combine dark algorithm and compact algorithm
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        // Author: Tamnt add phoenixTheme
        token: currentTheme === "dark" ? pepoTheme.dark : pepoTheme.light,
      }}
    >
      <Layout className="h-screen m-[-8]">
        <AuthContext.Provider value={value}>
          {value?.auth && (
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={setCollapsed}
              theme={currentTheme}
              className="border-r-[1px] border-r-[#d9d9d9]"
            >
              <div className="h-8 m-4 flex overflow-hidden">
                <img
                  src="assets/images/part_1.png"
                  alt=""
                  height={48}
                  width={48}
                />
                <img
                  src="assets/images/part_2.png"
                  alt=""
                  height={48}
                  width={120}
                  className="ml-1"
                />
              </div>
              <Menu
                theme={currentTheme}
                defaultSelectedKeys={["1"]}
                mode="inline"
                items={items}
                onClick={({ key }) => {
                  switch (key) {
                    default:
                      navigate(`/${key}`);
                      break;
                  }
                }}
              />
              <Menu
                theme={currentTheme}
                mode="inline"
                items={[
                  getItem(
                    currentTheme === "dark"
                      ? "Giao diện Light"
                      : "Giao diện Dark",
                    "changeTheme",
                    currentTheme === "dark" ? <Fi.FiSun /> : <Fi.FiMoon />
                  ),
                  getItem("Đăng xuất", "logout", <LogoutOutlined />),
                ]}
                selectable={false}
                onClick={({ key }) => {
                  switch (key) {
                    case "changeTheme":
                      setCurrentTheme(
                        currentTheme === "dark" ? "light" : "dark"
                      );
                      break;
                    case "logout":
                      setAuth(undefined);
                      console.log("you clicked logged out");
                      break;
                    default:
                      navigate(`/${key}`);
                      break;
                  }
                }}
              />
            </Sider>
          )}

          <Layout>
            {props.children}
            <Footer className="text-center">
              Ant Design ©2023 Created by Ant UED
            </Footer>
          </Layout>
        </AuthContext.Provider>
      </Layout>
    </ConfigProvider>
  );
}
