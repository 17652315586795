import * as React from "react";
import { Navigation } from "./navigation";
import { Layout, theme, Table, Pagination, Select, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PauseOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import * as SignalR from "@microsoft/signalr";
const { Content } = Layout;

export default function UserList() {
  const {
    token: { colorBgContainer },
    } = theme.useToken();
const socketValue = {
    //socketAddress: "http://localhost:7707/sockets",
    socketAddress: "https://commerce.phoenixcompany.vn/sockets",
};
  const handleEdit = (record) => {
    // Xử lý logic cho việc sửa bản ghi
  };

  const handleDelete = (key) => {
    // Xử lý logic cho việc xóa bản ghi
  };

  const handleHide = (key) => {
    // Xử lý logic cho việc tạm ẩn bản ghi
  };
  const columns = [
    {
      title: "Tên",
      dataIndex: "Name",
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "NameLogin",
    },
    {
      title: "SĐT",
      dataIndex: "SDT",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <span>
          <EditOutlined
            style={{ color: "blue", marginRight: 8 }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title={`Bạn có chắc muốn xóa tài khoản ${record.Name} ra khỏi hệ thống?`}
            onConfirm={() => handleDelete(record.key)}
            okText="OK" // Văn bản của nút OK
            cancelText="Cancel" // Văn bản của nút Cancel
            okButtonProps={{
              style: { backgroundColor: "#ED5A2A", color: "white" },
            }} // Tùy chỉnh màu sắc nút OK
            cancelButtonProps={{
              style: { background: "#275DAB", color: "white" },
            }} // Tùy chỉnh màu sắc nút Cancel
          >
            <DeleteOutlined style={{ color: "red", marginRight: 8 }} />
          </Popconfirm>
          <Popconfirm
            title={`Bạn có chắc muốn tạm ngưng hoạt động của tài khoản ${record.Name}?`}
            onConfirm={() => handleHide(record.key)}
            okText="OK"
            cancelText="Cancel"
            okButtonProps={{ style: { background: "green", color: "white" } }}
            cancelButtonProps={{ style: { background: "red", color: "white" } }}
          >
            <PauseOutlined style={{ color: "orange", marginRight: 8 }} />{" "}
          </Popconfirm>
        </span>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      Name: "Lê Bá Tòng 1",
      NameLogin: "tonglb",
      SDT: "0366027514",
    },
    {
      key: "2",
      Name: "Người 2",
      NameLogin: "nguoi2",
      SDT: "0987654322",
    },
    {
      key: "3",
      Name: "Người 3",
      NameLogin: "nguoi3",
      SDT: "0987654323",
    },
    {
      key: "4",
      Name: "Người 4",
      NameLogin: "nguoi4",
      SDT: "0987654324",
    },
    {
      key: "5",
      Name: "Người 5",
      NameLogin: "nguoi5",
      SDT: "0987654325",
    },
    {
      key: "6",
      Name: "Người 6",
      NameLogin: "nguoi6",
      SDT: "0987654326",
    },
    {
      key: "7",
      Name: "Người 7",
      NameLogin: "nguoi7",
      SDT: "0987654327",
    },
    {
      key: "8",
      Name: "Người 8",
      NameLogin: "nguoi8",
      SDT: "0987654328",
    },
    {
      key: "9",
      Name: "Người 9",
      NameLogin: "nguoi9",
      SDT: "0987654329",
    },
    {
      key: "10",
      Name: "Người 10",
      NameLogin: "nguoi10",
      SDT: "0987654330",
    },
    {
      key: "11",
      Name: "Người 11",
      NameLogin: "nguoi11",
      SDT: "0987654331",
    },
    {
      key: "12",
      Name: "Người 12",
      NameLogin: "nguoi12",
      SDT: "0987654332",
    },
    {
      key: "13",
      Name: "Người 13",
      NameLogin: "nguoi13",
      SDT: "0987654333",
    },
    {
      key: "14",
      Name: "Người 14",
      NameLogin: "nguoi14",
      SDT: "0987654334",
    },
    {
      key: "15",
      Name: "Người 15",
      NameLogin: "nguoi15",
      SDT: "0987654335",
    },
    {
      key: "16",
      Name: "Người 16",
      NameLogin: "nguoi16",
      SDT: "0987654336",
    },
    {
      key: "17",
      Name: "Người 17",
      NameLogin: "nguoi17",
      SDT: "0987654337",
    },
    {
      key: "18",
      Name: "Người 18",
      NameLogin: "nguoi18",
      SDT: "0987654338",
    },
    {
      key: "19",
      Name: "Người 19",
      NameLogin: "nguoi19",
      SDT: "0987654339",
    },
    {
      key: "20",
      Name: "Người 20",
      NameLogin: "nguoi20",
      SDT: "0987654340",
    },
  ];

  // Phân trang
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10, // Số mục trên mỗi trang
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const handlePageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value });
    };

    const Invoke_Socket = () => {
        
        console.log("connect socket--------");
        const connect = new SignalR.HubConnectionBuilder()
            .withUrl(socketValue.socketAddress, {
                skipNegotiation: true,
                transport: SignalR.HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();
        connect
            .start()
            .then(() => {
                connect.invoke("Connected", "WebhookFacebook");
                connect.on("CONNECTION_WebhookFacebook", (request) => {
                    console.log("request-webhook-----", request);
                });
            })
            .catch((err) => {
                console.log("kết nỗi socket lỗi", err);
            });
        
    };

    React.useEffect(() => {
        Invoke_Socket();
    }, []);

  return (
    <div className="flex flex-col flex-grow">
      <Navigation title={"Danh sách người dùng"} />
      <Content className="mx-6 my-4">
        <div style={{ height: "calc(100vh - 240px)" }}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onChange={handleTableChange}
            scroll={{ y: "calc(100vh - 270px)" }}
          />
        </div>
      </Content>
      <div className="flex justify-end mt-4">
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={data.length}
          onChange={(page, pageSize) => {
            setPagination({ ...pagination, current: page });
          }}
          showSizeChanger={false} // Ẩn chức năng thay đổi kích thước trang
          showQuickJumper={true} // Hiển thị hộp nhập trang nhanh
        />
        <Select
          defaultValue={pagination.pageSize}
          onChange={handlePageSizeChange}
          style={{ width: 120 }}
        >
          <Option value={5}>5 / page</Option>
          <Option value={10}>10 / page</Option>
          <Option value={50}>50 / page</Option>
        </Select>
      </div>
      <div className="text-center mt-2">
        Showing {pagination.pageSize} items on page {pagination.current}
      </div>
    </div>
  );
}
