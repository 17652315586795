import * as React from "react";
import * as SignalR from "@microsoft/signalr";

export default function TestPage() {
  const [connection, setConnection] = React.useState(null);
  function onConnecting() {
    const proxy = new SignalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_SOCKET_ADDRESS)
      .withAutomaticReconnect()
      .build();
    setConnection(proxy);
  }
  function signalR() {
    //connection.invoke("LcdUpdated", code);
  }
  React.useEffect(() => {
    onConnecting();
    return () => {
      try {
        connection.stop();
      } catch {}
      setConnection(null);
    };
  }, []);

  React.useEffect(() => {
    if (connection || connection?.state === "Disconnected") {
      connection
        .start()
        .then(() => {
          //   connection.on("LCD_GROUP_" + code, (response) => {
          //     //setData(response.data);
          //     window.location.reload();
          //   });
          //   connection.invoke("Connected", code);
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);
  return <div></div>;
}
