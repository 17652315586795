import * as React from "react";
import { Button, Card, Checkbox, Form, Input, Layout } from "antd";
import { AuthContext } from "../../layout/HorizontalLayout";
import { useNavigate } from "react-router-dom";
const { Content } = Layout;

export default function LoginPage() {
  const navigate = useNavigate();
  const { setAuth } = React.useContext(AuthContext);
  const [username, setUsername] = React.useState("admin");
  const [password, setPassword] = React.useState("demo");
  const onFinish = (values) => {
    setAuth("ok");
    navigate("/");
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="flex flex-col flex-grow justify-center">
      <Content className="mx-6 my-4 ">
        <Card className="md:w-full lg:w-1/2 m-auto">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input value={username} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password value={password} />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit" className="bg-blue-700">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Content>
    </div>
  );
}
