import * as React from "react";
import { Col, DatePicker, Form, Input, Layout, Row, Select, theme } from "antd";
import { Navigation } from "./navigation";
import Password from "antd/es/input/Password";
import { Option } from "antd/es/mentions";
const { Content } = Layout;

export default function UserCreate() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [role, setRole] = React.useState("user"); // Mặc định là người dùng (user)

  const onFinish = (values) => {
    // Thực hiện xử lý dữ liệu ở đây, ví dụ: gửi dữ liệu lên server
    console.log("Thông tin đã nhập:", values);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <Navigation title={"Tạo tài khoản"} />
      <Content style={{ margin: "24px 16px 0" }}>
        <Form name="notificationForm" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Mã tài khoản"
                labelCol={{ span: 24 }} // Đặt span của labelCol là 24 để label nằm phía trên
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mã tài khoản",
                  },
                ]}
              >
                <Input
                  placeholder="Mã tài khoản"
                  width="100%"
                  value="NV_001"
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Tên tài khoản"
                labelCol={{ span: 24 }} // Đặt span của labelCol là 24 để label nằm phía trên
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên tài khoản",
                  },
                ]}
              >
                <Input
                  placeholder="Tên tài khoản"
                  width="100%"
                  value={"TongLB"}
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Tên người dùng"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Tên tài khoản"
                  width="100%"
                  value={"Lê Bá Tòng"}
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Số điện thoại"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
              >
                <Input
                  placeholder="Số điện thoại"
                  width="100%"
                  value={"0366027514"}
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Email"
                labelCol={{ span: 24 }} // Đặt span của labelCol là 24 để label nằm phía trên
              >
                <Input
                  placeholder="Email"
                  width="100%"
                  value="tonglb@gmail.com"
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Phân quyền"
                labelCol={{ span: 24 }} // Đặt span của labelCol là 24 để label nằm phía trên
              >
                <Select value={role} onChange={(value) => setRole(value)}>
                  <Option value="admin">Admin</Option>
                  <Option value="user">Người dùng</Option>
                  <Option value="manager">Quản lý</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Mật khẩu"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
              >
                <Password
                  placeholder="Mật khẩu"
                  width="100%"
                  value={"123456"}
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Nhập lại mật khẩu"
                labelCol={{ span: 24 }}
                style={{ width: "100%" }}
              >
                <Password
                  placeholder="Nhập lại mật khẩu"
                  width="100%"
                  value={"123456"}
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
}
