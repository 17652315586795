const pepoTheme = {
  light: {
    //colorBgContainer: "#e9f2f9",
  },
  dark: {
    colorBgContainer: "#212134",
    //colorBgContainer: "#181826"
  },
};

export { pepoTheme };
