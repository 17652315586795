import * as React from "react";
import { Navigation } from "./navigation";
import { Layout, theme, Table, Pagination, Select, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, PauseOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
const { Content } = Layout;

export default function NotificationList() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const handleEdit = (record) => {
    // Xử lý logic cho việc sửa bản ghi
  };

  const handleDelete = (key) => {
    // Xử lý logic cho việc xóa bản ghi
  };

  const handleHide = (key) => {
    // Xử lý logic cho việc tạm ẩn bản ghi
  };
  const columns = [
    {
      title: "Mã thông báo",
      dataIndex: "Code",
    },
    {
      title: "Tên thông báo",
      dataIndex: "Name",
    },
    {
      title: "Ngày thông báo",
      dataIndex: "Date",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <span>
          <EditOutlined
            style={{ color: "blue", marginRight: 8 }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title={`Bạn có chắc muốn xóa tài khoản ${record.Name} ra khỏi hệ thống?`}
            onConfirm={() => handleDelete(record.key)}
            okText="OK" // Văn bản của nút OK
            cancelText="Cancel" // Văn bản của nút Cancel
            okButtonProps={{
              style: { backgroundColor: "#ED5A2A", color: "white" },
            }} // Tùy chỉnh màu sắc nút OK
            cancelButtonProps={{
              style: { background: "#275DAB", color: "white" },
            }} // Tùy chỉnh màu sắc nút Cancel
          >
            <DeleteOutlined style={{ color: "red", marginRight: 8 }} />
          </Popconfirm>
        </span>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      Code: "TB36533",
      Name: "Thông báo nghĩ 2/9",
      Date: "01/9/2023",
    },
    {
      key: "2",
      Code: "TB36534",
      Name: "Thông báo nghĩ 30/4",
      Date: "25/4/2023",
    },
    {
      key: "3",
      Code: "TB36535",
      Name: "Thông báo nghĩ Tết",
      Date: "10/2/2023",
    },
    {
      key: "4",
      Code: "TB36536",
      Name: "Thông báo nghĩ lễ 1/5",
      Date: "25/4/2023",
    },
    {
      key: "5",
      Code: "TB36537",
      Name: "Thông báo nghĩ lễ Quốc Khánh",
      Date: "2/9/2023",
    },
    {
      key: "6",
      Code: "TB36538",
      Name: "Thông báo nghĩ lễ Giỗ Tổ Hùng Vương",
      Date: "10/4/2023",
    },
    {
      key: "7",
      Code: "TB36539",
      Name: "Thông báo nghĩ lễ 8/3",
      Date: "8/3/2023",
    },
    {
      key: "8",
      Code: "TB36540",
      Name: "Thông báo nghĩ 1/1",
      Date: "1/1/2023",
    },
    {
      key: "9",
      Code: "TB36541",
      Name: "Thông báo nghĩ 30/4",
      Date: "30/4/2023",
    },
    {
      key: "10",
      Code: "TB36542",
      Name: "Thông báo nghĩ lễ Quốc Tế lao động",
      Date: "1/5/2023",
    },
    {
      key: "11",
      Code: "TB36543",
      Name: "Thông báo nghĩ 20/10",
      Date: "20/10/2023",
    },
    {
      key: "12",
      Code: "TB36544",
      Name: "Thông báo nghĩ 8/3",
      Date: "8/3/2023",
    },
    {
      key: "13",
      Code: "TB36545",
      Name: "Thông báo nghĩ 1/1",
      Date: "1/1/2023",
    },
    {
      key: "14",
      Code: "TB36546",
      Name: "Thông báo nghĩ 20/11",
      Date: "20/11/2023",
    },
    {
      key: "15",
      Code: "TB36547",
      Name: "Thông báo nghĩ lễ Quốc Tế Phụ Nữ",
      Date: "8/3/2023",
    },
  ];

  // Phân trang
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10, // Số mục trên mỗi trang
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  const handlePageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value });
  };
  return (
    <div className="flex flex-col flex-grow">
      <Navigation title={"Danh sách thông báo"} />
      <Content className="mx-6 my-4">
        <div style={{ height: "calc(100vh - 240px)" }}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            onChange={handleTableChange}
            scroll={{ y: "calc(100vh - 270px)" }}
          />
        </div>
      </Content>
      <div className="flex justify-end mt-4">
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={data.length}
          onChange={(page, pageSize) => {
            setPagination({ ...pagination, current: page });
          }}
          showSizeChanger={false} // Ẩn chức năng thay đổi kích thước trang
          showQuickJumper={true} // Hiển thị hộp nhập trang nhanh
          // showTotal={(total, range) =>
          //   `${range[0]}-${range[1]} of ${total} items`
          // }
        />
        <Select
          defaultValue={pagination.pageSize}
          onChange={handlePageSizeChange}
          style={{ width: 120 }}
        >
          <Option value={5}>5 / page</Option>
          <Option value={10}>10 / page</Option>
          <Option value={50}>50 / page</Option>
        </Select>
      </div>
      <div className="text-center mt-2">
        Showing {pagination.pageSize} items on page {pagination.current}
      </div>
    </div>
  );
}
