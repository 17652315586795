import { ArticlesList } from "./screens/articles";
import { LoginPage } from "./screens/auth";
import { NotificationCreate, NotificationList } from "./screens/notifications";
import TestPage from "./screens/test";
import { UserList, UserUserCreateList } from "./screens/users";

const AppRoutes = [
  {
    index: true,
    element: <UserList />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/users",
    element: <UserList />,
  },
  {
    path: "/notifications",
    element: <NotificationList />,
  },
  {
    path: "/users/create",
    element: <UserUserCreateList />,
  },
  {
    path: "/notifications/create",
    element: <NotificationCreate />,
  },
  {
    path: "/articles",
    element: <ArticlesList />,
  },
  {
    path: "/test",
    element: <TestPage />,
  },
];

export default AppRoutes;
