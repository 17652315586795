import * as React from "react";
import {
  Col,
  Form,
  Input,
  Layout,
  theme,
  DatePicker,
  Button,
  Row,
  Upload,
  message,
  Image,
} from "antd";
import { Navigation } from "./navigation";
import { UploadOutlined } from "@ant-design/icons";

const { Content } = Layout;

export default function NotificationCreate() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // Xử lý khi người dùng gửi biểu mẫu
  const onFinish = (values) => {
    // Thực hiện xử lý dữ liệu ở đây, ví dụ: gửi dữ liệu lên server
    console.log("Thông tin đã nhập:", values);
  };
  const [fileList, setFileList] = React.useState([]);

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <Navigation title={"Tạo thông báo"} />
      <Content style={{ margin: "24px 16px 0" }}>
        <Form name="notificationForm" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Mã thông báo"
                labelCol={{ span: 24 }} // Đặt span của labelCol là 24 để label nằm phía trên
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mã thông báo",
                  },
                ]}
              >
                <Input
                  placeholder="Mã thông báo"
                  width="100%"
                  value="TB_001"
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Tên thông báo"
                labelCol={{ span: 24 }} // Đặt span của labelCol là 24 để label nằm phía trên
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tên thông báo",
                  },
                ]}
              >
                <Input
                  placeholder="Mã thông báo"
                  width="100%"
                  value={"Thông báo nghĩ lễ 2/9"}
                  onChange={(e) => e}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Thời gian thông báo"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn thời gian thông báo",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <DatePicker
                  changeOnBlur
                  style={{ width: "100%" }}
                  showTime
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Nội dung thông báo"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập nội dung thông báo",
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input.TextArea style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="Tải lên ảnh" labelCol={{ span: 24 }}>
                <Upload
                  fileList={fileList}
                  beforeUpload={(file) => {
                    // Thêm logic kiểm tra và xử lý file ở đây
                    // Ví dụ: kiểm tra định dạng file, kích thước, ...
                    return true; // Trả về true để cho phép tải lên file
                  }}
                  onChange={({ file, fileList }) => {
                    // Xử lý khi có sự thay đổi trong danh sách file đã tải lên
                    setFileList(fileList);
                  }}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>Tải lên</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </div>
  );
}
