import * as React from "react";
import { Filter } from "./filter";
import { FileSearchOutlined, FileAddOutlined } from "@ant-design/icons";

import { Button, Layout, theme, Drawer, Row, Col, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;

export function Navigation({ title }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const headerStyle = {
    padding: 0,
    background: colorBgContainer,
    display: "flex",
    alignItems: "center",
    paddingX: "9px",
    justifyContent: "space-between",
  };
  const buttonContainerStyle = {
    marginLeft: "auto",
  };
  function onCreate() {
    navigate("/notifications/create");
  }
  function onCancel() {}
  function onSubmit() {}
  const showDrawer = () => {
    setOpen(true);
  };
  return (
    <React.Fragment>
      <Header style={headerStyle}>
        <div style={{ fontSize: 18, marginLeft: "20px" }}>{title}</div>
        <div style={buttonContainerStyle}>
          <Button
            style={{
              backgroundColor: "#ED5A2A",
              borderColor: "#ED5A2A",
              marginLeft: "3px",
              marginRight: "10px",
            }}
            type="primary"
            icon={<FileAddOutlined />}
            onClick={onCreate}
          >
            Thêm
          </Button>
          <Button
            style={{
              backgroundColor: "#275DAB",
              borderColor: "#275DAB",
              marginRight: "10px",
            }}
            type="primary"
            icon={<FileSearchOutlined />}
            onClick={showDrawer}
          >
            Lọc
          </Button>
        </div>
      </Header>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Thông tin tìm kiếm</span>
            <div>
              <Button
                onClick={onCancel}
                style={{ marginRight: 8, backgroundColor: "#275DAB" }}
                type="primary"
              >
                Hủy
              </Button>
              <Button
                style={{ backgroundColor: "#ED5A2A" }}
                onClick={onSubmit}
                type="primary"
              >
                Đồng ý
              </Button>
            </div>
          </div>
        }
        placement="right"
        onClose={onClose}
        visible={open}
        width={400}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Mã thông báo">
              <Input size="small" placeholder="Mã thông báo" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tên thông báo">
              <Input size="small" placeholder="Tên thông báo" />
            </Form.Item>
          </Col>
        </Row>
      </Drawer>
    </React.Fragment>
  );
}
