import * as React from "react";
import {
  HomeOutlined,
  SaveOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { Button, Layout, theme } from "antd";
import { useNavigate } from "react-router-dom";
const { Header } = Layout;

export function Navigation({ title }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const headerStyle = {
    padding: 0,
    background: colorBgContainer,
    display: "flex",
    alignItems: "center",
    paddingX: "9px",
    justifyContent: "space-between",
  };

  const buttonContainerStyle = {
    marginLeft: "auto",
  };
  function oncancel() {
    navigate("/users");
  }
  return (
    <React.Fragment>
      <Header style={headerStyle}>
        <div style={{ fontSize: 18, marginLeft: "20px" }}>{title}</div>
        <div style={buttonContainerStyle}>
          <Button
            style={{
              backgroundColor: "#ED5A2A",
              borderColor: "#ED5A2A",
              marginLeft: "3px",
              marginRight: "10px",
            }}
            type="primary"
            icon={<SaveOutlined />}
            //onClick={onCreate}
          >
            Lưu
          </Button>
          <Button
            style={{
              backgroundColor: "#275DAB",
              borderColor: "#275DAB",
              marginRight: "10px",
            }}
            type="primary"
            icon={<RightCircleOutlined />}
            onClick={oncancel}
          >
            Hủy
          </Button>
        </div>
      </Header>
    </React.Fragment>
  );
}
